import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import { Box } from '@mui/material';
import Footer from "./Footer";

type Props = {};

export const Layout: FC<Props> = () => {
  return (
    <>
      <Header />
      <Box sx={{ mt: 10, mb: 2, minHeight: "70vh" }}>
        <Outlet />
      </Box>
      <Footer />
    </>
  );
};