import { CloseOutlined } from "@mui/icons-material";
import { Stack, IconButton } from "@mui/material";

type Props = {
  onClose: () => void;
};
export const ModalClose = ({ onClose }: Props) => {
  return (
    <Stack justifyContent={"end"}>
      <IconButton onClick={onClose}>
        <CloseOutlined />
      </IconButton>
    </Stack>
  );
};
