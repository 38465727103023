import { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Typography } from "@mui/material";

type Props = {
  title?: string;
  value?: string;
  onChange?: (data: string) => void;
  error?: boolean;
  helperText?: string | false;
};

export function CustomTextEditor({
  title,
  value,
  onChange,
  error,
  helperText,
}: Props) {
  const handleChange = (event: any, editor: any) => {
    const data = editor.getData();

    if (onChange) {
      onChange(data);
    }

    console.log({ data });
  };

  return (
    <div>
      {title && (
        <Typography
          variant="body1"
          gutterBottom
        >
          {title}
        </Typography>
      )}

      <CKEditor
        data={value}
        editor={ClassicEditor}
        onChange={handleChange}
        // config={
        // {
        // toolbar: [
        //   "heading",
        //   "|",
        //   "bold",
        //   "italic",
        //   "|",
        //   "link",
        //   "list",
        //   "bulletedList",
        //   "numberedList",
        //   "blockQuote",
        // ],
        // }
        // }
        onError={(error: any) => {
          console.error("There was a problem initializing the editor.", error);
        }}
        onReady={(editor: any) => {
          // You can store the "editor" and use when it is needed.
          console.log("Editor is ready to use!", editor);
        }}
      />

      <Typography
        variant="body1"
        color={error ? "error" : "inherit"}
        gutterBottom
      >
        {helperText}
      </Typography>
    </div>
  );
}
