import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { FC } from "react";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { PageLink } from "../custom/PageLink";
import AuthUserBadge from "../user/AuthUserBadge";
import { LoginDrawer } from "../auth/LoginDrawer/LoginDrawer";
import { useAuth } from "../../lib/hooks/useAuth";
import {
  DashboardCustomizeOutlined,
  MedicalServicesOutlined,
} from "@mui/icons-material";

export const Header: FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <AppBar
      position="fixed"
      sx={{
        // bgcolor: "background.default",
        // color: "text.primary",
        zIndex: (theme) => theme.zIndex.drawer - 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        boxShadow: "none",
      }}
      variant="elevation"
    >
      <Container>
        <Box sx={{ py: 2, display: "flex", alignItems: "center" }}>
          <PageLink
            href="/"
            color={"inherit"}
            key={"home"}
          >
            <Typography
              sx={{
                flexGrow: 1,
                fontSize: "1.2rem",
                fontWeight: "400",
                // color: "primary.main",
              }}
            >
              find-md
            </Typography>
          </PageLink>
          <Box sx={{ ml: "auto" }}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
            >
              {isAuthenticated && [
                <Link
                  key={"dashboard"}
                  variant="button"
                  href="/dashboard"
                  underline="none"
                  sx={{ color: "inherit", display: "flex" }}
                >
                  {/* <DashboardCustomizeOutlined /> */}
                  <Typography
                    sx={{ ml: 1, display: { xs: "none", md: "block" } }}
                  >
                    Dashboard
                  </Typography>
                </Link>,

                <Link
                  key={"doctors"}
                  variant="button"
                  href="/dashboard/doctors"
                  underline="none"
                  sx={{ color: "inherit", display: "flex" }}
                >
                  {/* <MedicalServicesOutlined /> */}
                  <Typography
                    sx={{ ml: 1, display: { xs: "none", md: "block" } }}
                  >
                    Doctors
                  </Typography>
                </Link>,
              ]}
              <AuthUserBadge />
            </Stack>
          </Box>
        </Box>
      </Container>
      <LoginDrawer />
    </AppBar>
  );
};

export default Header