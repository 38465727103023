import {
  Avatar,
  Box,
  Container,
  Drawer,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import LoginForm from "./LoginForm";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Copyright } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  selectAuthDrawerIsOpen,
  setAuthDrawerIsOpen,
} from "../../../store/reducers";
import { ModalClose } from "../../custom/ModalClose";
type Props = {
  onClose?: () => void;
};
export function LoginDrawer({ onClose }: Props) {
  const isOpen = useSelector(selectAuthDrawerIsOpen);
  const dispatch = useDispatch();

  const handleOnCloese = () => {
    if (onClose) {
      onClose();
    }
    dispatch(setAuthDrawerIsOpen(false));
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: {
            xs: "100%",
            sm: 600,
          },
        },
      }}
    >
      <ModalClose onClose={handleOnCloese} />
      <Container>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
          >
            Sign in
          </Typography>
          <LoginForm />
          <Grid container>
            <Grid
              item
              xs
            >
              <Link
                href="#"
                variant="body2"
              >
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="#"
                variant="body2"
              >
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <Copyright sx={{ mt: 5 }} />
        </Box>
      </Container>
    </Drawer>
  );
}
