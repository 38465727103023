import { useEffect } from "react";
import { bbBackendApi } from "../api/api";
import { useSelector, useDispatch } from "react-redux";
import { selectUsers, setUsers } from "../../store/reducers";
import { apiRoutes } from "../constants/apiRoutes";

interface Props {
  bloodType?: string;
  state?: string;
  district?: string;
}

const useUsers = (props?: Props) => {
  const users = useSelector(selectUsers);
  const dispatch = useDispatch();

  const getAllUsers = async () => {
    return await bbBackendApi
      .get(apiRoutes.users)
      .then((response) => {
        console.log(response);
        dispatch(setUsers(response.data));
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  };

  useEffect(() => {
    console.info("useGetDonors props changed", JSON.stringify(props));

    bbBackendApi.get("/api/users").then((response) => {});
  }, [JSON.stringify(props)]);

  return {
    users,
    getAllUsers,
  };
};

export default useUsers;
