import { Container } from "@mui/system";
import { HomeServiceCards } from "./components/HomeServiceCards";
import { HomeExplainJobPortal } from "./components/HomeExplainJobPortal";
import { Box } from "@mui/material";
import { HomeLandingHero } from "./components/HomeLandingHero";

export const Home = () => {
  return (
    <div>
      <Container>
        <HomeLandingHero />
      </Container>
    </div>
  );
};

export default Home;
