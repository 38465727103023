import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { SideDrawer } from "../../../../../components/UI/SideDrawer";
import { DoctorForm } from "../../../../../components/doctor/DoctorForm";

const WelcomeMessage = () => {
  return (
    <>
      <Typography variant="h4">Doctors</Typography>
      <Typography variant="body1">This is your home page</Typography>
    </>
  );
};

export function DoctorsList() {
  const [openCreate, setOpenCreate] = useState(true);

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const Toolbar = () => {
    return (
      <Stack
        spacing={2}
        justifyContent={"flex-end"}
      >
        <Button onClick={handleOpenCreate}>Add New Doctor</Button>
      </Stack>
    );
  };

  return (
    <Box>
      <WelcomeMessage />
      <Toolbar />

      <SideDrawer
        open={openCreate}
        onClose={handleCloseCreate}
        width={"40%"}
      >
        <DoctorForm />
      </SideDrawer>
    </Box>
  );
}
