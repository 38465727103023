import { Box, Button, Typography } from "@mui/material";
import { useAuth } from "../../../lib/hooks/useAuth";
import { useDispatch } from "react-redux";
import { setAuthDrawerIsOpen } from "../../../store/reducers";
import { LoginOutlined } from "@mui/icons-material";

type Props = {
  children: React.ReactNode;
};
export function PrivatePageLayout({ children }: Props) {
  const { user, isAuthenticated } = useAuth();
  const dispatch = useDispatch();

  if (!isAuthenticated) {
    return (
      <Box
        sx={{
          textAlign: "center",
          height: "40vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          src="/stop-animated.gif"
          alt="logo"
        />
        <Typography variant="h4">Your are not Authorized</Typography>
        <Typography variant="body1">Please login to continue</Typography>
        <Box mt={2}>
          <Button
            onClick={() => dispatch(setAuthDrawerIsOpen(true))}
            startIcon={<LoginOutlined />}
          >
            Login
          </Button>
        </Box>
      </Box>
    );
  }

  return <>{children}</>;
}
