import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Donor } from "../../lib/types/donor";
import { RootState } from "../store";

export interface AuthState {
  user?: Donor;
}

const initialState: AuthState = {
  user: undefined,
};

export const authSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setAuthUser: (state, action: PayloadAction<Donor | undefined>) => {
      state.user = action.payload;
    },
  },
});

export const { setAuthUser } = authSlice.actions;

export const selectAuthUser = (state: RootState) => {
  return state.auth.user;
};

export default authSlice.reducer;
