import { useDispatch } from "react-redux";
import { bbBackendApi } from "../api/api";
import { useSelector } from "react-redux";
import { selectJobs, setJobs } from "../../store/reducers";
import type { AxiosResponse } from "axios";
import type { TJob } from "../types/job";
import { apiRoutes } from "../constants/apiRoutes";
import { useEffect } from "react";

export function useJobs() {
  const dispatch = useDispatch();
  const jobs = useSelector(selectJobs);

  const fetchJobs = async () => {
    return await bbBackendApi
      .get(apiRoutes.jobs)
      .then((response: AxiosResponse<TJob[]>) => {
        dispatch(setJobs(response.data));
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  };

  const createJob = async (job: Partial<TJob>) => {
    return await bbBackendApi
      .post(apiRoutes.jobs, job)
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return { error: error.response.data.message };
      });
  };

  const updateJob = async (id: string, job: Partial<TJob>) => {
    return await bbBackendApi
      .put(`${apiRoutes.jobs}/${id}`, job)
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return { error: error.response.data.message };
      });
  };

  const deleteJob = async (jobId: string) => {
    return await bbBackendApi
      .delete(apiRoutes.jobs + `/${jobId}`)
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return { error: error.response.data.message };
      });
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  return {
    jobs,
    fetchJobs,
    createJob,
    updateJob,
    deleteJob,
  };
}
