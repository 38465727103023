import {
  Box,
  Container,
  Stack,
  TextField,
  Typography,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useJobs } from "../../lib/hooks/useJobs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { CustomTextEditor } from "../textEditor/CustomTextEditor";
import type { TJob } from "../../lib/types/job";

type Props = {
  onSubmit: () => void;
  onCancel: () => void;
  job?: TJob;
};

const validationSchema = yup.object({
  title: yup.string().required("Required"),
  description: yup.string().required("Required"),
  location: yup.string().required("Required"),
  company: yup.string().required("Required"),
  toDate: yup.string(),
  active: yup.boolean(),
  deleted: yup.boolean(),
});
export function AddNewJobForm({ job, onSubmit, onCancel }: Props) {
  const { createJob, updateJob } = useJobs();
  const isNew = !job;

  const formik = useFormik({
    initialValues: isNew
      ? {
          title: "",
          description: "",
          location: "",
          company: "",
          email: "",
          fromDate: undefined,
          toDate: undefined,
          active: true,
          deleted: false,
        }
      : {
          title: job?.title,
          description: job?.description,
          location: job?.location,
          company: job?.company,
          active: job?.active,
          deleted: job?.deleted,
        },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      if (!isNew) {
        updateJob(job?.id, values).then(() => {
          onSubmit();
        });
        return;
      }

      createJob(values).then(() => {
        formik.resetForm();
        onSubmit();
      });

      // onSubmit();
    },
  });

  console.log(formik.values);

  return (
    <Box>
      <Container sx={{ py: 4 }}>
        <Typography variant="h6">
          {isNew ? "Add New Job" : "Edit Job"}
        </Typography>
        <Typography variant="body2">
          Add all the informations about the job
        </Typography>
        <Box sx={{ mt: 4 }}>
          <form onSubmit={formik.handleSubmit}>
            <Stack
              direction="column"
              spacing={2}
            >
              <TextField
                fullWidth
                id="title"
                name="title"
                label="Title"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />

              <CustomTextEditor
                title="Job Description"
                value={formik.values.description}
                onChange={(value) => formik.setFieldValue("description", value)}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
              <TextField
                fullWidth
                id="location"
                name="location"
                label="Location"
                value={formik.values.location}
                onChange={formik.handleChange}
                error={
                  formik.touched.location && Boolean(formik.errors.location)
                }
                helperText={formik.touched.location && formik.errors.location}
              />
              <TextField
                fullWidth
                id="company"
                name="company"
                label="Company"
                value={formik.values.company}
                onChange={formik.handleChange}
                error={formik.touched.company && Boolean(formik.errors.company)}
                helperText={formik.touched.company && formik.errors.company}
              />
              <Stack hidden>
                {/* <DatePicker
                  name="fromDate"
                  label="From Date"
                  value={
                    formik.values.fromDate && dayjs(formik.values.fromDate)
                  }
                  disablePast={isNew}
                  onChange={(newValue) => {
                    formik.setFieldValue(
                      "fromDate",
                      dayjs(newValue).toISOString()
                    );
                    // formik.setFieldValue("startDate", newValue);
                  }}
                  slotProps={{
                    textField: {
                      id: "fromDate",
                      helperText:
                        formik.touched.fromDate && formik.errors.fromDate,
                      error:
                        formik.touched.fromDate &&
                        Boolean(formik.errors.fromDate),
                    },
                  }}
                />

                <DatePicker
                  name="toDate"
                  label="To Date"
                  value={formik.values.toDate && dayjs(formik.values.toDate)}
                  disablePast
                  onChange={(newValue) => {
                    formik.setFieldValue(
                      "toDate",
                      dayjs(newValue).toISOString()
                    );
                  }}
                  disabled={!formik.values.fromDate}
                  slotProps={{
                    textField: {
                      id: "toDate",
                      helperText: formik.touched.toDate && formik.errors.toDate,
                      error:
                        formik.touched.toDate && Boolean(formik.errors.toDate),
                    },
                  }}
                /> */}
              </Stack>
              <FormControlLabel
                label="Active"
                control={
                  <Switch
                    id="isActive"
                    name="isActive"
                    checked={formik.values.active}
                    onChange={(e: any) => {
                      formik.setFieldValue("active", e.target.checked);
                    }}
                    title="Active"
                  />
                }
              />

              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  type="reset"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Container>
    </Box>
  );
}
