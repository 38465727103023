import { Box, Stack, CircularProgress } from "@mui/material";
import { TextField, Typography, Paper, Button, Chip } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { CustomTextEditor } from "../../../../../components/textEditor/CustomTextEditor";
import { useEmail } from "../../../../../lib";
import {
  CloudUploadOutlined,
  DeleteOutline,
  EmailOutlined,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useState } from "react";

export function AdminEmail() {
  const { sendEmail } = useEmail();
  const { enqueueSnackbar } = useSnackbar();
  const [sending, setSending] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      message: "",
      subject: "",
      attachments: [],
    },
    validationSchema: yup.object({
      email: yup.string().email("Invalid email address").required("Required"),
      message: yup.string().required("Required"),
      subject: yup.string().required("Required"),
      attachments: yup.array(),
    }),
    onSubmit: (values) => {
      console.log(values);
      setSending(true);
      sendEmail(
        values.email,
        values.subject,
        values.message,
        values.attachments
      )
        .then((response) => {
          if (response.error) {
            enqueueSnackbar(response.error, { variant: "error" });
          } else {
            enqueueSnackbar("Email sent successfully", { variant: "success" });
            formik.resetForm();
          }
        })
        .finally(() => {
          setSending(false);
        });
    },
  });

  return (
    <div>
      <Typography variant="h6">Admin Email</Typography>
      <Typography variant="body2">This is the admin email page</Typography>

      <Paper sx={{ p: 2, mt: 2 }}>
        <form onSubmit={formik.handleSubmit}>
          <Stack
            direction={"column"}
            gap={1}
          >
            <Stack>
              <Button
                role={undefined}
                component="label"
                tabIndex={-1}
                startIcon={<CloudUploadOutlined />}
              >
                Upload File
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    console.log(
                      e.target.files?.[0],
                      typeof e.target.files?.[0]
                    );
                    formik.setFieldValue("attachments", [
                      ...formik.values.attachments,
                      e.target.files?.[0],
                    ]);
                  }}
                />
              </Button>
            </Stack>
            <TextField
              variant="standard"
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <TextField
              variant="standard"
              fullWidth
              id="subject"
              name="subject"
              label="Subject"
              value={formik.values.subject}
              onChange={formik.handleChange}
              error={formik.touched.subject && Boolean(formik.errors.subject)}
              helperText={formik.touched.subject && formik.errors.subject}
            />

            <CustomTextEditor
              value={formik.values.message}
              onChange={(value) => formik.setFieldValue("message", value)}
              error={formik.touched.message && Boolean(formik.errors.message)}
            />
            <Stack>
              {formik.values.attachments &&
                formik.values.attachments.map((attachment: File, index) => (
                  <Chip
                    key={index}
                    label={attachment.name}
                    onDelete={() => {
                      formik.setFieldValue(
                        "attachments",
                        formik.values.attachments.filter((_, i) => i !== index)
                      );
                    }}
                    variant="outlined"
                  />
                ))}
            </Stack>
            <Stack justifyContent={"flex-end"}>
              {sending ? (
                <CircularProgress size={24} />
              ) : (
                [
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<EmailOutlined />}
                  >
                    Send
                  </Button>,
                  <Button
                    type="button"
                    variant="text"
                    onClick={() => formik.resetForm()}
                    startIcon={<DeleteOutline />}
                    color="inherit"
                  >
                    Reset
                  </Button>,
                ]
              )}
            </Stack>
          </Stack>
        </form>
      </Paper>
    </div>
  );
}
