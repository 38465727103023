import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Donor } from "../../lib/types/donor";
import { DeleteOutline, PhoneOutlined } from "@mui/icons-material";
import { bbBackendApi } from "../../lib/api/api";
import { useSnackbar } from "notistack";

type Props = {
  donor: Donor;
};

const StyledDonorName = styled(Typography)(({ theme }) => ({
  textTransform: "capitalize",
}));

export const DonorSearchCard = ({ donor }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = () => {
    bbBackendApi
      .delete(`/api/users/${donor.id}`)
      .then((response) => {
        console.log(response);
        enqueueSnackbar(response.data.message, { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      });
  };

  return (
    <Card key={donor.id} variant="outlined">
      <CardContent>
        <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
          <Box>
            <StyledDonorName key={donor.id} variant="body1">
              {donor.name}
            </StyledDonorName>
            <Typography variant="body2">{donor.phone}</Typography>
            <Typography variant="body2">{donor.email}</Typography>
          </Box>
          <Box>
            <Tooltip title={`Call ${donor.name}`}>
              <IconButton>
                <PhoneOutlined />
              </IconButton>
            </Tooltip>
            {process.env.NODE_ENV !== "production" && (
              <Tooltip title={`Delete ${donor.name}`}>
                <IconButton onClick={handleDelete} color="error">
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DonorSearchCard;
