import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Donor } from "../../lib/types/donor";
import { RootState } from "../store";

export interface UsersState {
  users: Donor[];
}

const initialState: UsersState = {
  users: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<Donor[]>) => {
      state.users = action.payload;
    },
  },
});

export const { setUsers } = usersSlice.actions;

export const selectUsers = (state: RootState) => {
  return state.users.users;
};

export default usersSlice.reducer;
