import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/store"; // Import your Redux store
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const firebaseConfig = {
  apiKey: "AIzaSyCu7oqDz5Kd2JoaERkxiegpb3Nc_n0rc7I",
  authDomain: "nadhirsha.firebaseapp.com",
  projectId: "nadhirsha",
  storageBucket: "nadhirsha.appspot.com",
  messagingSenderId: "393793460657",
  appId: "1:393793460657:web:ecbd9dd4878e6593144590",
  measurementId: "G-N4KVER81SP",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Provider store={store}>
          <PersistGate
            loading={null}
            persistor={persistor}
          ></PersistGate>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </LocalizationProvider>
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
