import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import DonorSerachResults from "../../components/search/DonorSearchResults";
import LandingSearchPanel from "../../components/search/LandingSearchPanel";
import useGetDonors from "../../lib/hooks/useGetDonors";
import { searchBloodProps } from "../../lib/types";

export const SearchLanding = () => {
  const [searchKeys, setSearchKeys] = useState<searchBloodProps>();
  const searchResults = useGetDonors(searchKeys ?? {});

  const handleSearch = (values: searchBloodProps) => {
    setSearchKeys(values);
  };

  const handleReset = () => {};

  useEffect(() => {
    document.title = "Search - Blood Bank";
  }, []);

  return (
    <Container>
      <LandingSearchPanel
        onSubmit={handleSearch}
        onCancel={handleReset}
      />
      <DonorSerachResults results={searchResults} />
    </Container>
  );
};

export default SearchLanding;
