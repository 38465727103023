import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  CircularProgress,
  Avatar,
  Chip,
  Divider,
} from "@mui/material";
import { useAuth } from "../../../lib/hooks/useAuth";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { setAuthDrawerIsOpen } from "../../../store/reducers";

export function LoginForm() {
  const { user, loginUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isLogginIn, setIsLogginIn] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isLogginIn) {
      return;
    }

    const data = new FormData(event.currentTarget);

    setIsLogginIn(true);

    const response = await loginUser(
      data.get("email") as string,
      data.get("password") as string
    );
    setIsLogginIn(false);

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      return;
    }

    dispatch(setAuthDrawerIsOpen(false));
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit}
      sx={{ mt: 1 }}
    >
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        defaultValue={user?.email}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
      />
      <FormControlLabel
        control={
          <Checkbox
            value="remember"
            color="primary"
          />
        }
        label="Remember me"
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        {isLogginIn ? (
          <CircularProgress
            color="inherit"
            size={14}
          />
        ) : (
          "Sign In"
        )}
      </Button>
    </Box>
  );
}

export default LoginForm;
