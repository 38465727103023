import { useEffect, useMemo } from "react";
import { selectAuthUser, setAuthUser } from "../../store/reducers";
import { store } from "../../store/store";
import { bbBackendApi } from "../api/api";
import { useDispatch, useSelector } from "react-redux";

function decodeToken(token: string) {
  try {
    // Decode the token (assuming it's a JWT)
    const payload = JSON.parse(atob(token.split(".")[1]));
    return payload;
  } catch (error) {
    // Handle decoding errors
    console.error("Error decoding token:", error);
    return null;
  }
}

export function isAuthenticated() {
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  }
  const decodedToken = decodeToken(token);
  console.log({ decodedToken });
  return decodedToken && decodedToken.exp * 1000 > Date.now();
}

export function useAuth() {
  const dispatch = useDispatch();
  const user = useSelector(selectAuthUser);
  const token = localStorage.getItem("token");

  const isAuthenticated =
    token && decodeToken(token) && Date.now() < decodeToken(token).exp * 1000;

  useEffect(() => {
    if (token) {
      dispatch(setAuthUser(user));
    }
  }, [token]);

  const loginUser = (email: string, password: string) => {
    return bbBackendApi
      .post("/login", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("token", response.data.token);
        }
        if (response.data.user) {
          dispatch(setAuthUser(response.data.user));
        }
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return { error: error.response.data.message };
      });
  };

  const logoutUser = () => {
    localStorage.removeItem("token");
    store.dispatch(setAuthUser(undefined));
  };

  return {
    isAuthenticated,
    user,
    loginUser,
    logoutUser,
  };
}
