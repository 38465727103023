import { Container } from "@mui/material";
import { useEffect } from "react";
import DonorSerachResults from "../../components/search/DonorSearchResults";
import useUsers from "../../lib/hooks/useUsers";

export const UsersLanding = () => {
  const { users, getAllUsers } = useUsers();

  const fetchDonors = () => {
    getAllUsers();
  };

  useEffect(() => {
    document.title = "Users - Blood Bank";
    fetchDonors();
  }, []);

  return (
    <Container>
      <DonorSerachResults results={users} />
    </Container>
  );
};

export default UsersLanding;
