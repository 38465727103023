import {
  EmailOutlined,
  HomeOutlined,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import {
  Box,
  Container,
  List,
  ListItem,
  Typography,
  ListItemText,
  styled,
  ListItemAvatar,
} from "@mui/material";
import { Outlet } from "react-router-dom";
import { PageLink } from "../../../components/custom/PageLink";

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    // display: "none",
  },
}));

export function UserDashboard() {
  return (
    <Container sx={{ py: 2 }}>
      <Box
        sx={{ py: 2 }}
        hidden
      >
        <Typography variant="h6">Super Admin Dashboard</Typography>
        <Typography variant="body1">
          This is the super admin dashboard
        </Typography>
      </Box>
      {/* <Box sx={{ display: "flex", gap: 2 }}>
        <Box sx={{ p: 0, width: { md: "15%", xs: "10%" } }}>
          <List
            disablePadding
            sx={{ width: "100%", bgcolor: "background.paper" }}
          >
            <PageLink href="/dashboard">
              <ListItem sx={{ color: "inherit" }}>
                <ListItemAvatar>
                  <HomeOutlined
                    sx={{ color: "text.secondary", fontSize: 20 }}
                  />
                </ListItemAvatar>
                <ListItemText primary="Home" />
              </ListItem>
            </PageLink>
            <PageLink href="/dashboard/jobs">
              <ListItem>
                <ListItemAvatar>
                  <WorkOutlineOutlined
                    sx={{ color: "text.secondary", fontSize: 20 }}
                  />
                </ListItemAvatar>
                <ListItemText primary="Jobs" />
              </ListItem>
            </PageLink>
            <PageLink href="/dashboard/doctors">
              <ListItem>
                <ListItemAvatar>
                  <WorkOutlineOutlined
                    sx={{ color: "text.secondary", fontSize: 20 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Doctos"
                  secondary="Manage doctors profile"
                />
              </ListItem>
            </PageLink>
            <PageLink href="/dashboard/email">
              <ListItem>
                <ListItemAvatar>
                  <EmailOutlined
                    sx={{ color: "text.secondary", fontSize: 20 }}
                  />
                </ListItemAvatar>
                <ListItemText primary="Email" />
              </ListItem>
            </PageLink>
          </List>
        </Box>
        <Box sx={{ p: 2, width: { md: "85%", xs: "90%" } }}>
          <Outlet />
        </Box>
      </Box> */}
      <Outlet />
    </Container>
  );
}
