import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Chip,
} from "@mui/material";
import {
  CorporateFareOutlined,
  DeleteOutline,
  EditOutlined,
  PinDropRounded,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { RawHtml } from "../textEditor/RawHtml";
import { useJobs } from "../../lib/hooks/useJobs";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { selectJobById } from "../../store/reducers";

type Props = {
  jobId: string;
  onClose: () => void;
  onEdit: () => void;
};
export function JobDetails({ jobId, onClose, onEdit }: Props) {
  const job = useSelector(selectJobById(jobId));
  const { deleteJob, fetchJobs } = useJobs();
  const { enqueueSnackbar } = useSnackbar();

  const handleEdit = () => {
    onEdit();
  };

  const handleDelete = () => {
    if (!job) {
      return;
    }
    deleteJob(job.id)
      .then(() => {
        enqueueSnackbar("Job Deleted", { variant: "success" });
        onClose();
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      })
      .finally(() => {
        fetchJobs();
      });
  };

  if (!job) {
    return null;
  }

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      justifyContent={"space-between"}
    >
      <Box>
        <Typography variant="body1">Job Details</Typography>

        <Typography
          variant="h4"
          sx={{ mb: 2 }}
          color="primary"
        >
          {job.title}
          <Chip
            label={job.active ? "Active" : "Inactive"}
            color={job.active ? "success" : "error"}
            size="small"
            sx={{ ml: 1 }}
            variant="outlined"
          />
        </Typography>

        <Typography
          variant="body1"
          sx={{ mt: 2 }}
        >
          Description:
        </Typography>
        <RawHtml html={job.description} />

        <Box>
          <List
            dense
            disablePadding
            sx={{
              mt: 2,
            }}
          >
            <ListItem>
              <ListItemIcon>
                <PinDropRounded />
              </ListItemIcon>
              <ListItemText
                primary="Location"
                secondary={job.location}
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <CorporateFareOutlined />
              </ListItemIcon>
              <ListItemText
                primary="Company"
                secondary={job.company}
              />
            </ListItem>
          </List>
        </Box>
      </Box>
      <Box sx={{ minWidth: 200 }}>
        <List
          disablePadding
          dense
        >
          <ListItem>
            <ListItemText
              primary="Created on"
              secondary={dayjs(job.createdAt).format("DD/MM/YYYY")}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Last updated on"
              secondary={dayjs(job.updatedAt).format("DD/MM/YYYY")}
            />
          </ListItem>
          <ListItem onClick={handleEdit}>
            <ListItemIcon>
              <EditOutlined />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </ListItem>
          <ListItem onClick={handleDelete}>
            <ListItemIcon>
              <DeleteOutline />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </ListItem>
        </List>
      </Box>
    </Stack>
  );
}
