import { Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import * as yup from "yup";
import { bbBackendApi } from "../../lib/api/api";

const validationSchema = yup.object({
  name: yup.string().required("Required"),
  email: yup.string().email().required("Required"),
  phone: yup
    .number()
    .typeError("Must be a number")
    .min(10, "Min be 10 digits")
    .required("Required"),
  password: yup
    .string()
    .required("Required")
    .min(8, "Minimum 8 characters")
    .max(16, "Maximum 16 characters"),
});

export const RegisterLanding = () => {
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      bbBackendApi
        .post("/api/users", values)
        .then((response) => {
          console.log(response);
          enqueueSnackbar(response.data.message, { variant: "success" });
          formik.resetForm();
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.response.data.message, { variant: "error" });
        });
    },
  });

  useEffect(() => {
    document.title = "Register - Blood Bank";
  }, []);
  return (
    <Container>
      <Typography variant="h3">Register</Typography>
      <Typography variant="body2">Enter your details</Typography>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={6}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />

              <TextField
                fullWidth
                id="phone"
                name="phone"
                label="Phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />

              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />

              <Button type="submit" variant="contained">
                Register
              </Button>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RegisterLanding;
