import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Donor } from "../../lib/types/donor";
import { RootState } from "../store";
import type { TJob } from "../../lib/types/job";

export interface JobsState {
  jobs: TJob[];
}

const initialState: JobsState = {
  jobs: [],
};

export const jobsSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setJobs: (state, action: PayloadAction<TJob[]>) => {
      state.jobs = action.payload;
    },
  },
});

export const { setJobs } = jobsSlice.actions;

export const selectJobs = (state: RootState) => {
  return state.jobs.jobs;
};

export const selectJobById = (id: string) => (state: RootState) => {
  return state.jobs.jobs.find((job) => job.id === id);
};

export default jobsSlice.reducer;
