import { RefreshOutlined, WorkOutlineOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AddNewJobDrawer } from "../../../../components/jobs/AddNewJobDrawer";
import { useJobs } from "../../../../lib/hooks/useJobs";
import type { TJob } from "../../../../lib/types/job";
import { SideDrawer } from "../../../../components/UI/SideDrawer";
import { JobDetails } from "../../../../components/jobs/JobDetails";
import { AddNewJobForm } from "../../../../components/jobs/AddNewJobForm";
import { DataGrid, type GridRowParams } from "@mui/x-data-grid";
import dayjs from "dayjs";

export default function JobsList() {
  const [showForm, setShowForm] = useState(false);
  const [selectedJob, setSelectedJob] = useState<TJob | null>(null);
  const [edit, setEdit] = useState(false);
  const { jobs, fetchJobs } = useJobs();

  useEffect(() => {
    fetchJobs();
  }, []);

  const handleSubmit = () => {
    fetchJobs();
    setShowForm(false);
  };

  const handleEditSubmit = () => {
    fetchJobs();
    setEdit(false);
  };

  const handleRefresh = () => {
    fetchJobs();
  };

  const handleView = (job: TJob) => () => {
    setSelectedJob(job);
    setEdit(false);
  };

  const handleRowClick = (params: GridRowParams<TJob>) => {
    handleView(params.row)();
  };

  return (
    <div>
      <Typography variant="h6">Jobs List</Typography>
      <Typography variant="body1">This is the jobs list</Typography>
      <Box sx={{ py: 2 }}>
        <Stack>
          <Button
            onClick={handleRefresh}
            startIcon={<RefreshOutlined />}
          >
            Refresh
          </Button>
          <Button
            variant="contained"
            startIcon={<WorkOutlineOutlined />}
            onClick={() => setShowForm(true)}
          >
            Add New Job
          </Button>
        </Stack>

        <Box sx={{ mt: 2 }}>
          <DataGrid
            rows={jobs}
            columns={[
              { field: "title", headerName: "Title", flex: 1 },
              { field: "company", headerName: "Company", flex: 1 },
              { field: "location", headerName: "Location", flex: 1 },
              {
                field: "createdAt",
                headerName: "Created At",
                flex: 1,
                renderCell: (params) => {
                  return dayjs(params.value).format("YYYY-MM-DD");
                },
              },
              {
                field: "updatedAt",
                headerName: "Updated At",
                flex: 1,
                renderCell: (params) => {
                  return dayjs(params.value).format("YYYY-MM-DD");
                },
              },
            ]}
            onRowClick={handleRowClick}
            disableColumnMenu
          />
          <List hidden>
            {jobs.map((job) => (
              <ListItem
                key={job.id}
                disablePadding
              >
                <ListItemIcon>
                  <WorkOutlineOutlined />
                </ListItemIcon>
                <ListItemButton onClick={handleView(job)}>
                  <ListItemText
                    primary={job.title}
                    secondary={`${job.company} - ${job.location}`}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>

      {showForm && (
        <SideDrawer
          open
          onClose={() => setShowForm(false)}
          width={"80%"}
        >
          <AddNewJobForm
            onSubmit={handleSubmit}
            onCancel={() => setShowForm(false)}
          />
        </SideDrawer>
      )}

      {selectedJob && !edit && (
        <SideDrawer
          open
          onClose={() => setSelectedJob(null)}
          width={"80%"}
        >
          <JobDetails
            jobId={selectedJob.id}
            onClose={() => setSelectedJob(null)}
            onEdit={() => setEdit(true)}
          />
        </SideDrawer>
      )}

      {selectedJob && edit && (
        <SideDrawer
          open
          onClose={() => setSelectedJob(null)}
          width={"80%"}
        >
          <AddNewJobForm
            job={selectedJob}
            onSubmit={handleEditSubmit}
            onCancel={() => setSelectedJob(null)}
          />
        </SideDrawer>
      )}
    </div>
  );
}
