import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  phoneNumber: yup.string().required("Required"),
  email: yup.string().email("Invalid email address").required("Required"),
  address1: yup.string().required("Required"),
  address2: yup.string().required("Required"),
  city: yup.string().required("Required"),
  state: yup.string().required("Required"),
  district: yup.string().required("Required"),
  pincode: yup.string().required("Required"),
});

const WelcomeMessage = () => (
  <div>
    <Typography variant="h4">Onboard New Doctor</Typography>
    <Typography variant="body1">
      Register a new doctor and related informations{" "}
    </Typography>
  </div>
);

export const DoctorForm = () => {
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      district: "",
      pincode: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <div>
      <WelcomeMessage />
      <Box sx={{ pt: 2 }}>
        <Typography variant="body1">Basic informations</Typography>

        <form onSubmit={formik.handleSubmit}>
          <div className="grid sm:grid-cols-4 gap-4">
            <TextField
              id="firstName"
              name="firstName"
              label="First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              className="col-span-2"
            />
            <TextField
              id="lastName"
              name="lastName"
              label="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              className="col-span-2"
            />
            <TextField
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              className="col-span-2"
            />
            <TextField
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
              className="col-span-2"
            />

            <TextField
              id="address1"
              name="address1"
              label="Address 1"
              value={formik.values.address1}
              onChange={formik.handleChange}
              error={formik.touched.address1 && Boolean(formik.errors.address1)}
              helperText={formik.touched.address1 && formik.errors.address1}
              className="col-span-2"
            />

            <TextField
              id="address2"
              name="address2"
              label="Address 2"
              value={formik.values.address2}
              onChange={formik.handleChange}
              error={formik.touched.address2 && Boolean(formik.errors.address2)}
              helperText={formik.touched.address2 && formik.errors.address2}
              className="col-span-2"
            />

            <TextField
              id="city"
              name="city"
              label="City"
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              className="col-span-1"
            />

            <TextField
              id="state"
              name="state"
              label="State"
              value={formik.values.state}
              onChange={formik.handleChange}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
              className="col-span-1"
            />

            <TextField
              id="district"
              name="district"
              label="District"
              value={formik.values.district}
              onChange={formik.handleChange}
              error={formik.touched.district && Boolean(formik.errors.district)}
              helperText={formik.touched.district && formik.errors.district}
              className="col-span-1"
            />

            <TextField
              id="pincode"
              name="pincode"
              label="Pincode"
              value={formik.values.pincode}
              onChange={formik.handleChange}
              error={formik.touched.pincode && Boolean(formik.errors.pincode)}
              helperText={formik.touched.pincode && formik.errors.pincode}
              className="col-span-1"
              type="number"
              inputProps={{
                inputMode: "numeric",
              }}
            />
          </div>
          <div className="flex justify-end pt-4 space-x-2">
            <Button
              onClick={() => formik.resetForm()}
              variant="outlined"
              className="text-capitalize ml-2"
            >
              Reset
            </Button>
            <Button
              type="submit"
              variant="contained"
              className="text-capitalize"
            >
              Submit
            </Button>
          </div>
        </form>
      </Box>
    </div>
  );
};
