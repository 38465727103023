export const routes = {
  home: "/",
  login: "/login",
  register: "/register",
  search: "/find-donors",
  users: "/users",
  findDonors: "/find-donors",
  test: "/test",
  dashboard: "/dashboard",
};
