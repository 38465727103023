import { bbBackendApi } from "../api/api";

export function useEmail() {
  const sendEmail = (
    email: string,
    subject: string,
    message: string,
    attachments?: Blob[]
  ) => {
    console.log(email, subject, message, attachments);
    const form = new FormData();

    form.append("email", email);
    form.append("subject", subject);
    form.append("message", message);
    if (attachments) {
      for (let i = 0; i < attachments.length; i++) {
        form.append(`attachments`, attachments[i]);
      }
    }

    return bbBackendApi
      .post("/api/email", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return { error: error.response.data.message };
      });
  };

  return {
    sendEmail,
  };
}
