import './App.css';
import { Layout } from './components/UI/Layout';
import { Routes, Route } from 'react-router';
import Home from './pages/home/Home';
import RegisterLanding from './pages/register/RegisterLanding';
import NotFound from './pages/common/notFound';
import SearchLanding from "./pages/search/SearchLanding";
import TestPage from "./pages/test/TestPages";
import UsersLanding from "./pages/users/UsersLanding";
import UserDashboard from "./pages/dashboard/UserDashboard";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline, PaletteMode, createTheme } from "@mui/material";
import { getDesignTokens } from "./lib";
import { createContext, useMemo, useState } from "react";
import { UserDashboardHome } from "./pages/dashboard/UserDashboard/pages/UserDashboardHome";
import JobsList from "./pages/dashboard/UserDashboard/pages/JobsList";
import { PrivatePageLayout } from "./components/auth/LoginDrawer/PrivatePageLayout";
import { AdminEmail } from "./pages/dashboard/UserDashboard/pages/AdminEmail/AdminEmail";
import { DoctorsList } from "./pages/dashboard/UserDashboard/pages/Doctors/DoctorsList";

export const colorModeContext = createContext({ toggleColorMode: () => {} });

function App() {
  const preMode: PaletteMode = (localStorage.getItem("mode") as any) ?? "light";
  const [mode, setMode] = useState<PaletteMode>(preMode);
  const colorMode = useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) => {
          const newMode = prevMode === "light" ? "dark" : "light";
          localStorage.setItem("mode", newMode);
          return newMode;
        });
      },
    }),
    [mode]
  );

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <>
      <colorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route
              path="/"
              element={<Layout />}
            >
              <Route
                index
                element={<Home />}
              />
              <Route
                path="find-donors"
                element={<SearchLanding />}
              />
              <Route
                path="register"
                element={<RegisterLanding />}
              />
              <Route
                path="test"
                element={<TestPage />}
              />
              <Route
                path="users"
                element={<UsersLanding />}
              />
              <Route
                path="dashboard"
                element={
                  <PrivatePageLayout>
                    <UserDashboard />
                  </PrivatePageLayout>
                }
              >
                <Route
                  index
                  element={<UserDashboardHome />}
                />
                <Route
                  path="/dashboard/doctors"
                  element={<DoctorsList />}
                />
                <Route
                  path="/dashboard/jobs"
                  element={<JobsList />}
                />
                <Route
                  path="/dashboard/email"
                  element={<AdminEmail />}
                />
              </Route>
              <Route
                path="*"
                element={<NotFound />}
              />
            </Route>
          </Routes>
        </ThemeProvider>
      </colorModeContext.Provider>
    </>
  );
}

export default App;
