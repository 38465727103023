import {
  Typography,
  Box,
  Button,
  Link,
  Grid,
  List,
  ListItem,
} from "@mui/material";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../../../lib/hooks/useAuth";

export function UserDashboardHome() {
  const { user } = useAuth();
  return (
    <Box>
      <Typography variant="h4">Welcome {user?.name}</Typography>
      <Typography variant="body1">This is your home page</Typography>
      <Outlet />
    </Box>
  );
}
