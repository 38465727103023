import {
  AppRegistrationOutlined,
  DashboardCustomizeOutlined,
  Login,
  SearchOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { useAuth } from "../../../lib/hooks/useAuth";
import { useDispatch } from "react-redux";
import { setAuthDrawerIsOpen } from "../../../store/reducers";

// # styled
const HeroTitle = styled(Typography)(({ theme }) => ({
  fontSize: "3rem",
  fontWeight: 600,
  textTransform: "uppercase",
  color: theme.palette.primary.main,
  // textAlign: "center",
  lineHeight: 0.8,
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    // textAlign: "right",
    fontSize: "4rem",
  },
}));

const IntroText = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  color: theme.palette.text.primary,
}));

// #
export function HomeLandingHero() {
  const { user } = useAuth();
  const dispatch = useDispatch();

  return (
    <Container>
      <Grid
        container
        sx={{ minHeight: 400, alignItems: "center" }}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <Box sx={{}}>
            <HeroTitle>
              Welcome <br />
            </HeroTitle>
            <IntroText>Login to access dashboard</IntroText>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <Stack
            spacing={2}
            justifyContent={"center"}
          >
            {!user
              ? [
                  <Button
                    startIcon={<Login />}
                    variant="contained"
                    onClick={() => dispatch(setAuthDrawerIsOpen(true))}
                  >
                    Login
                  </Button>,
                ]
              : [
                  <Tooltip title="Dashboard">
                    <Card
                      sx={{ p: 2 }}
                      variant="elevation"
                      elevation={2}
                      onClick={() => (window.location.href = "/dashboard")}
                    >
                      <DashboardCustomizeOutlined sx={{ fontSize: "4rem" }} />
                    </Card>
                  </Tooltip>,
                ]}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
