import { Box, Container, Grid, Link, Typography } from "@mui/material";

export const Footer = () => {
  return (
    <Box>
      <Box
        sx={{
          minHeight: 100,
          py: 5,
        }}
      >
        <Container>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Typography variant="h5" sx={{ fontWeight: 200 }}>
                nadhirsha.com
              </Typography>

              <Typography variant="body2" color="text.secondary">
                nadhirsha.com is more than a personal website. this is a
                platform for me to show case and provide resources to others. i
                hope you will like it.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{ py: 2 }}>
        <Typography variant="body2" color="text.secondary" align="center">
          {"Copyright © "}
          <Link color="inherit" href="https://nadhirsha.com/">
            nadhirsha.com
          </Link>
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
